import {newCOLORS} from 'core/styles';
import styled from 'styled-components';

export const TableContainer = styled.div`
    margin: 24px;
    padding-bottom: 24px;
    overflow: auto;

    table {
        font-family: var(--copy-font);
        font-size: 14px;
        line-height: 20px;
        border: 1px solid #ddd;
        border-radius: 16px;
        border-collapse: separate;
        color: ${newCOLORS.gray500};
        width: 100%;
    }
    thead {
        border: 1px solid #ddd;
        border-radius: 16px;
    }
    thead th {
        font-weight: 700;
        background-color: ${newCOLORS.gray100};
    }
    thead th:first-child {
        border-top-left-radius: 16px;
    }
    thead th:last-child {
        border-top-right-radius: 16px;
    }

    thead th,
    tbody td {
        padding: 16px 12px;
    }

    tbody td {
        border-bottom: 1px solid ${newCOLORS.gray200};
    }
    tbody tr:last-child td {
        border-bottom: none;
    }

    .column-label {
        display: flex;
        align-items: center;

        .label {
            display: block;
            margin-right: 12px;
        }
    }

    .column-label.sortable:hover {
        cursor: pointer;
    }
`;
