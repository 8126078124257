import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';

import {
    getListingAnalyticsIfNeeded,
    getListingAnalytics,
} from 'features/dashboard/actions';
import {selectListingAnalytics} from 'features/dashboard/selectors';
import {selectAccountId, selectAccountById} from 'features/accounts/selectors';
import {getAccountListingsIfNeeded} from 'features/listings/actions';
import DashboardTable from 'features/dashboard/components/DashboardTable/DashboardTable';
import {selectCurrentUser} from '../../users/selectors';
import {getCurrentMonth} from 'utils/dates';

let DashboardTableContainer = (props) => {
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        props.getAccountListingsIfNeeded(props.accountId);
    }, []);

    useEffect(() => {
        if (props.listings && props.listings.length) {
            const accountIds = {};

            for (let key in props.listings) {
                if (!(props.listings[key] in accountIds)) {
                    accountIds[props.listings[key].account_id] = true;
                }
            }

            Object.keys(accountIds).forEach((accountId) => {
                if (userCanViewAnalytics(props.user, accountId)) {
                    props.getListingAnalyticsIfNeeded(accountId, getCurrentMonth);
                }
            });
        }
    }, [props.listings]);

    const toggleShowAll = () => {
        setShowAll((prevShowAll) => {
            return !prevShowAll;
        });
    };

    const userCanViewAnalytics = (user, listingAccountId) => {
        const hasAllPerm = [
            'report-view-all-trackingAndTransactionByEvent',
            'report-view-all-clickAndCallVolumeByListing',
        ].some((permission) => {
            return user.permissions.includes(permission);
        });
        if (hasAllPerm) return true;
        return [
            'report-view-own-clickAndCallVolumeByListing',
            'report-view-own-trackingAndTransactionByEvent',
        ].some((permission) => {
            return (
                user.permissions.includes(permission) &&
                user.account_id === listingAccountId
            );
        });
    };

    return (
        <DashboardTable
            {...props}
            showAll={showAll}
            toggleShowAll={toggleShowAll}
            userCanViewAnalytics={userCanViewAnalytics}
        />
    );
};

export default connect(
    (state, ownProps) => {
        return {
            account: selectAccountById(state, ownProps),
            accountId: selectAccountId(state, ownProps),
            listings: selectListingAnalytics(state, ownProps),
            analytics: state.dashboard,
            isFetching: state.listings.isFetching,
            modal: state.modal,
            user: selectCurrentUser(state),
        };
    },
    {
        getAccountListingsIfNeeded,
        getListingAnalytics,
        getListingAnalyticsIfNeeded,
    },
)(DashboardTableContainer);
