import React, {useEffect, useState} from 'react';
import Loading from 'features/generic/components/Loading/Loading';
import {isEmpty} from 'utils/helpers';
import {formatListingTypeDisplayName} from 'utils/helpers';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import * as styles from '../DashboardListings/styles.js';
import {TableContainer} from './styles.js';
import DashboardTableMenu from './DashboardTableMenu.js';
import DashboardTableSortIcon from './DashboardTableSortIcon.js';

const formatData = (data) => {
    return data.map((item) => {
        return {...item, location: `${item.address?.city}, ${item.address?.region}`};
    });
};

const columnHelper = createColumnHelper();

const columns = [
    columnHelper.accessor('listing_id', {
        header: () => ' ',
        cell: (info) => {
            return <DashboardTableMenu listingId={info.renderValue()} />;
        },
        enableSorting: false,
    }),
    columnHelper.accessor('listing_name', {
        header: () => 'Listing',
        cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor('is_active', {
        header: () => 'Status',
        cell: (info) => (info.renderValue() === true ? 'Active' : 'Inactive'),
        sortDescFirst: false,
    }),
    columnHelper.accessor('location', {
        header: () => 'Location',
        cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor('listing_type', {
        header: () => 'Package',
        cell: (info) => formatListingTypeDisplayName(info.renderValue()),
    }),
    columnHelper.accessor('impressions', {
        header: () => 'Views MTD',
        cell: (info) => info.renderValue(),
        sortUndefined: 'last',
    }),
    columnHelper.accessor('clicks', {
        header: () => 'Clicks MTD',
        cell: (info) => info.renderValue(),
        sortUndefined: 'last',
    }),
    columnHelper.accessor('calls', {
        header: () => 'Calls MTD',
        cell: (info) => info.renderValue(),
        sortUndefined: 'last',
    }),
    columnHelper.accessor('listing_card_impressions', {
        header: () => 'Card Imp. MTD',
        cell: (info) => info.renderValue(),
        sortUndefined: 'last',
    }),
    columnHelper.accessor('cpa_total', {
        header: () => 'Avg. CPA',
        cell: (info) => (info.renderValue() ? `$${info.renderValue()}` : ''),
        sortUndefined: 'last',
        sortingFn: (rowA, rowB, columnId) => {
            const rowAValue = Number(rowA.original[columnId].replace(/,/g, ''));
            const rowBValue = Number(rowB.original[columnId].replace(/,/g, ''));

            return rowAValue - rowBValue;
        },
        sortDescFirst: true,
    }),
];

const DashboardTable = (props) => {
    const [data, setData] = useState(() => []);
    const [sorting, setSorting] = useState([]);

    useEffect(() => {
        if (props.listings.length) {
            setData(formatData(props.listings));
        }
    }, [props.listings]);

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(), //client-side sorting
        onSortingChange: setSorting,
        state: {
            sorting,
        },
    });

    if (props.isFetching) {
        return (
            <TableContainer>
                <Loading style={styles.loading} message="Getting listings..." />
            </TableContainer>
        );
    }

    if (!props.isFetching && isEmpty(props.listings)) return null;

    return (
        <TableContainer>
            <table>
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th key={header.id}>
                                    <div
                                        className={`column-label ${
                                            header.column.getCanSort() ? 'sortable' : ''
                                        }`}
                                        onClick={header.column.getToggleSortingHandler()}
                                        title={
                                            header.column.getCanSort()
                                                ? header.column.getNextSortingOrder() ===
                                                  'asc'
                                                    ? 'Sort ascending'
                                                    : header.column.getNextSortingOrder() ===
                                                      'desc'
                                                    ? 'Sort descending'
                                                    : 'Clear sort'
                                                : undefined
                                        }>
                                        <span className="label">
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                      header.column.columnDef.header,
                                                      header.getContext(),
                                                  )}
                                        </span>
                                        {header.column.getCanSort() && (
                                            <DashboardTableSortIcon
                                                sortDirection={header.column.getIsSorted()}
                                            />
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <td key={cell.id}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext(),
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </TableContainer>
    );
};

export default DashboardTable;
