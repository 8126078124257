import React, {useState} from 'react';
import Modal from 'features/modal/components/Modal/Modal';
import IconMore from 'features/icons/IconMore';
import {newCOLORS} from 'core/styles';
import styled from 'styled-components';
import Link from 'next/link';

const StyledTableMenu = styled.div`
    svg:hover {
        cursor: pointer;
    }

    .table-modal {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 200px;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
            0px 4px 8px 3px rgba(0, 0, 0, 0.15);

        a {
            display: block;
            padding: 12px 16px;

            &:hover {
                text-decoration: underline;
            }
        }
    }
`;

export default (props) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <StyledTableMenu>
            <IconMore
                rotate="90"
                fill={newCOLORS.gray400}
                onClick={() => {
                    setShowModal(true);
                }}
            />
            {showModal && (
                <Modal handleClose={() => setShowModal(false)}>
                    <div className="table-modal">
                        <Link href={`/listing/${props.listingId}/details`}>
                            Edit Listing
                        </Link>
                    </div>
                </Modal>
            )}
        </StyledTableMenu>
    );
};
