import {COLORS, bp} from 'core/styles';
import styled from 'styled-components';

export const Container = styled.div`
    padding: 15px;
    display: block;
    margin: 0;
    background-color: #fff;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;

    ${bp('md')} {
        padding: 0 30px 24px;
    }
    ${(props) => (props.$css ? props.$css : '')};
`;

export let Empty = styled.div`
    width: 120px;
    height: 24px;
    filter: blur(14px);
    position: relative;
    top: 14px;
    left: 0;
    opacity: 0.5;
    background-color: ${COLORS.grayFont};
`;
