import {createSelector} from 'reselect';

import {selectCurrentUser} from 'features/users/selectors';
import {selectAccountIdParam} from 'features/accounts/selectors';
import {sortDescending, sortAscending, lower, isEmpty} from 'utils/helpers';
import {
    formatSortableDate,
    getFormatted,
    getSevenDays,
    getThirtyDays,
    getNinetyDays,
} from 'utils/dates';

const getSortKey = (state) => state.billing.sortKey;
const getAccountAnalytics = (state) => state.dashboard.accountAnalytics;
const getListingAnalytics = (state) => state.dashboard.listingAnalytics;
const getListings = (state) => state.listings.entities;
const getOwnAccountId = (state) => state.accounts.currentAccountId;

export const selectIterableAccountAnalytics = createSelector(
    [getAccountAnalytics, selectAccountIdParam, getOwnAccountId],
    (accountAnalytics, accountId, ownAccountId) => {
        const analytics = accountAnalytics[accountId ? accountId : ownAccountId];
        if (!analytics) return [];
        return analytics;
    },
);

export const selectAccountAnalytics = createSelector(
    [selectIterableAccountAnalytics],
    (analytics) => {
        return [
            ...analytics.sort((a, b) =>
                sortDescending(formatSortableDate(a.date), formatSortableDate(b.date)),
            ),
        ];
    },
);

export const selectListings = createSelector([getListings], (listings) => {
    return listings;
});

export const selectListingAnalytics = createSelector(
    [getListings, getListingAnalytics, selectAccountIdParam, getOwnAccountId],
    (listings, listingAnalytics, accountId, ownAccountId) => {
        const id = accountId || ownAccountId;

        const mapped = Object.keys(listings).map((key) => {
            return {
                ...listings[key],
                ...(listingAnalytics[key] ? listingAnalytics[key] : {}),
            };
        });

        const reversed = [...mapped.reverse()];

        return reversed;
    },
);

export const selectThirtyDays = createSelector([selectAccountAnalytics], (events) => {
    return events.filter((e) => e !== getFormatted(new Date()));
});

export const selectSevenDayTotal = createSelector([selectAccountAnalytics], (events) => {
    if (!events) return null;
    const thirtyDays = events
        .filter((event) => {
            return formatSortableDate(event.date) >= formatSortableDate(getSevenDays());
        })
        .reduce(
            (acc, cur) => {
                return {
                    clicks: acc.clicks + cur.clicks,
                    impressions: acc.impressions + cur.impressions,
                    totalCalls: acc.totalCalls + cur.totalCalls,
                };
            },
            {clicks: 0, billableCalls: 0, totalCalls: 0, impressions: 0},
        );

    return thirtyDays;
});

export const selectThirtyDayTotal = createSelector([selectAccountAnalytics], (events) => {
    if (!events) return null;
    const thirtyDays = events
        .filter((event) => {
            return formatSortableDate(event.date) >= formatSortableDate(getThirtyDays());
        })
        .reduce(
            (acc, cur) => {
                let a = {
                    clicks: acc.clicks + cur.clicks,
                    impressions: acc.impressions + cur.impressions,
                    totalCalls: acc.totalCalls + cur.totalCalls,
                };
                return a;
            },
            {clicks: 0, totalCalls: 0, impressions: 0},
        );
    return thirtyDays;
});

export const selectNinetyDayTotal = createSelector([selectAccountAnalytics], (events) => {
    if (!events) return null;
    const thirtyDays = events
        .filter((event) => {
            return formatSortableDate(event.date) >= formatSortableDate(getNinetyDays());
        })
        .reduce(
            (acc, cur) => {
                return {
                    clicks: acc.clicks + cur.clicks,
                    impressions: acc.impressions + cur.impressions,
                    totalCalls: acc.totalCalls + cur.totalCalls,
                };
            },
            {clicks: 0, billableCalls: 0, totalCalls: 0, impressions: 0},
        );

    return thirtyDays;
});
