import React from 'react';
import propTypes from 'prop-types';

export default function IconPlay({
    width = '24',
    height = '24',
    fill = '#000',
    rotate = '0',
}) {
    return (
        <svg
            fill="none"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            style={{transform: `rotate(${rotate}deg)`}}>
            <path d="m8 19 11-7-11-7z" fill={fill} />
        </svg>
    );
}

IconPlay.propTypes = {
    width: propTypes.string,
    height: propTypes.string,
    fill: propTypes.string,
    rotate: propTypes.string,
};
