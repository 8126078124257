import {newCOLORS} from 'core/styles';
import IconPlay from 'features/icons/IconPlay';
import React from 'react';
import styled from 'styled-components';

const SortIcon = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    svg:first-child {
        margin-bottom: -8px;
    }
`;

const DashboardTableSortIcon = (props) => {
    return (
        <SortIcon>
            <IconPlay
                height="16"
                width="16"
                rotate="270"
                fill={
                    props.sortDirection === 'asc' ? newCOLORS.gray500 : newCOLORS.gray300
                }
            />
            <IconPlay
                height="16"
                width="16"
                rotate="90"
                fill={
                    props.sortDirection === 'desc' ? newCOLORS.gray500 : newCOLORS.gray300
                }
            />
        </SortIcon>
    );
};

export default DashboardTableSortIcon;
